export function sendMsgToParent(payload) {
    if (window?.parent?.postMessage) {
        window.parent.postMessage(payload, "*");
    }
}

export function bindParentToStdout() {
    const logMethods = ["log", "warn", "info", "error"];
    const originalMethods = {};

    // Bind console methods to send messages to parent

    logMethods.forEach((method) => {
        const consoleMethod = console[method].bind(console);
        originalMethods[method] = consoleMethod;

        console[method] = function (...args) {
            // consoleMethod.apply(console, args);

            const sanitizedArgs = args.map((arg) => {
                if (typeof arg === "object" && arg !== null) {
                    if (arg instanceof Promise) {
                        return arg.toString();
                    } else if (arg instanceof Date) {
                        return arg.toString();
                    }

                    try {
                        return JSON.parse(JSON.stringify(arg));
                    } catch (e) {
                        return arg.toString();
                    }
                } else if (typeof arg === "function") {
                    return arg.toString();
                } else if (arg === undefined) {
                    return "undefined";
                } else if (arg === null) {
                    return "null";
                } else if (typeof arg === "symbol") {
                    return arg.toString();
                }

                return arg;
            });

            sendMsgToParent({
                type: "IFRAME_LOG",
                method,
                payload: sanitizedArgs,
                platform: "react",
            });
        };
    });

    // Setup global error handler to capture unhandled errors

    if (window) {
        window.onerror = function (message, source, lineno, colno, error) {
            sendMsgToParent({
                type: "IFRAME_LOG",
                method: "throw-error",
                payload: { message, source, lineno, colno, error },
                platform: "react",
            });

            // return true; // Prevent default browser error handling
        };
    }

    return function cleanUp() {
        logMethods.forEach(function restoreMethod(method) {
            console[method] = originalMethods[method];
        });

        if (window) {
            window.onerror = null;
        }
    };
}
