import { bindParentToStdout } from "./logs";

/**
 * WARNING: This component is used internally by the us and it
 * should not be edited.
 */

export default function InternalWrapper({ children }) {
    bindParentToStdout()
    return <div>{children}</div>;
}